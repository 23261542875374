const ENVIRONMENT = process.env.REACT_APP_APP_ENV || 'dev'
console.log({ ENVIRONMENT })
let config

if (ENVIRONMENT === 'dev') {
    config = {
        app_env: ENVIRONMENT,
        apiUrl: 'http://localhost:4500/api/v1',
        HOST_WEB_URL: 'http://localhost:5042/home',
        WEB_BASE_URL: 'http://localhost:3000',
        HOST_DOMAIN: 'http://localhost:5042/',
        mediaUrl: 'https://localhost:4500/media/',
        persona_mode: 'sandbox',
        persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
        s3Endpoint: 'https://crewmate-media-local.s3.us-west-2.amazonaws.com/' /** always end with slash '/' */,
        // googleAPI_KEY: 'AIzaSyBXi4YfjakPz6aE2hz_8tIg3J85c8ZmRXE',
        googleAPI_KEY: 'AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is',
        // GOOGLE_CLIENTID: '1038466327649-d0h5kif8r3qffravcd7uhf2hon4ci8v2.apps.googleusercontent.com',
        GOOGLE_CLIENTID: '640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
        FACEBOOK_APPID: '237438817209817',
        stripePublisherKey: 'pk_test_51Iu3xqBryti39zXvQNb9k2efXmKmEpiKqyXcS13X45bqOtmk9UCKzRKZX5CEuOUl0WArEm6W0SJkLIhFxOIf7EsX006Ltaz5Gz',
    }
} else if (ENVIRONMENT === 'production') {
    console.log = () => {}
    config = {
        app_env: ENVIRONMENT,
        apiUrl: 'https://nodejs-6421992307235124.kloudbeansite.com/api/v1',
        HOST_WEB_URL: 'https://host.crewmatesapp.com/home',
        WEB_BASE_URL: 'http://crewmatesapp.com',
        HOST_DOMAIN: 'https://host.crewmatesapp.com/',
        mediaUrl: 'nodejs-6421992307235124.kloudbeansite.com/media/',
        persona_mode: 'sandbox',
        persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
        s3Endpoint: 'https://crewmate-production.s3.us-west-2.amazonaws.com/',
        googleAPI_KEY: 'AIzaSyCyeyo0XBjhWKaKgxqv-jK-9yOGzyTUvDo',
        GOOGLE_CLIENTID: '640276684298-oein71eoqe5qlbu532avlep4h1837a1r.apps.googleusercontent.com',
        FACEBOOK_APPID: '237438817209817',
        stripePublisherKey: 'pk_live_51Iu3xqBryti39zXvUMfgKOAJO53ioQhTJiWKGqRAaAntmjeqkXOTMRbOl7iRiSIFikD3L997n09adLfdpwFObkdx00b5RcV0Xb',
    }
} else if (ENVIRONMENT === 'staging') {
    // console.log = () => {}
    config = {
        app_env: ENVIRONMENT,
        apiUrl: 'https://devapi.crewmatesapp.com/api/v1',
        HOST_WEB_URL: 'https://devhost.crewmatesapp.com/home',
        HOST_DOMAIN: 'https://devhost.crewmatesapp.com/',
        mediaUrl: 'https://devapi.crewmatesapp.com/media/',
        persona_mode: 'sandbox',
        persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
        s3Endpoint: 'https://crewmates-dev.s3.us-west-2.amazonaws.com/',
        googleAPI_KEY: 'AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is',
        GOOGLE_CLIENTID: '640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
        FACEBOOK_APPID: '237438817209817',
        stripePublisherKey: 'pk_test_51Iu3xqBryti39zXvQNb9k2efXmKmEpiKqyXcS13X45bqOtmk9UCKzRKZX5CEuOUl0WArEm6W0SJkLIhFxOIf7EsX006Ltaz5Gz',
    }
}
// else if (ENVIRONMENT === 'staging') {
//     config = {
//         app_env:ENVIRONMENT,
//         apiUrl: 'https://devapi.crewmatesapp.com/api/v1',
//         HOST_WEB_URL: 'https://devhost.crewmatesapp.com/home',
//         HOST_DOMAIN: 'https://devhost.crewmatesapp.com/',
//         mediaUrl: 'https://devapi.crewmatesapp.com/media/',
//         persona_mode: 'sandbox',
//         persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
//         s3Endpoint: 'https://crewmates-dev.s3.us-west-2.amazonaws.com/',
//         // googleAPI_KEY: 'AIzaSyBXi4YfjakPz6aE2hz_8tIg3J85c8ZmRXE',
//         googleAPI_KEY:'AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is',
//         // GOOGLE_CLIENTID: '1038466327649-d0h5kif8r3qffravcd7uhf2hon4ci8v2.apps.googleusercontent.com',
//         GOOGLE_CLIENTID:'640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
//         FACEBOOK_APPID: '237438817209817',
//         stripePublisherKey:"pk_test_51Iu3xqBryti39zXvQNb9k2efXmKmEpiKqyXcS13X45bqOtmk9UCKzRKZX5CEuOUl0WArEm6W0SJkLIhFxOIf7EsX006Ltaz5Gz"
//     }
// }
// else if (ENVIRONMENT === 'production') {
//     console.log=()=>{}
//     config = {
//         app_env:ENVIRONMENT,
//         apiUrl: 'https://api.crewmatesapp.com/api/v1',
//         HOST_WEB_URL: 'https://host.crewmatesapp.com/home',
//         HOST_DOMAIN: 'https://host.crewmatesapp.com/',
//         mediaUrl: 'https://api.crewmatesapp.com/media/',
//         persona_mode: 'sandbox',
//         persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm',
//         s3Endpoint: 'https://crewmate-production.s3.us-west-2.amazonaws.com/',
//         googleAPI_KEY: 'AIzaSyCyeyo0XBjhWKaKgxqv-jK-9yOGzyTUvDo',
//         GOOGLE_CLIENTID: '640276684298-oein71eoqe5qlbu532avlep4h1837a1r.apps.googleusercontent.com',
//         FACEBOOK_APPID: '237438817209817',
//         stripePublisherKey:"pk_live_51Iu3xqBryti39zXvUMfgKOAJO53ioQhTJiWKGqRAaAntmjeqkXOTMRbOl7iRiSIFikD3L997n09adLfdpwFObkdx00b5RcV0Xb"
//     }
// }

export const AppConfig = config
